import React, { ReactNode } from "react";
import { ResourceAmountsType, ResourceType } from "api/game/GameType";
import {
  Box,
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Tooltip,
  Typography,
} from "@mui/material";
import { resourceTypeToResourceImage } from "utils/styling_helpers";
import { styled, useTheme } from "@mui/material/styles";
import { ColonyTileType } from "api/game/GameType";
export interface Props {
  colony: ColonyTileType;
}

const StyledCard = styled(Card)(({ theme }) => ({
  boxShadow: theme.shadows[3],
  "&:hover": {
    boxShadow: theme.shadows[10],
  },
  borderRadius: theme.shape.borderRadius,
  display: "inline-block",
  margin: theme.spacing(2),
}));

export const Component: React.FC<Props> = ({ colony }) => {
  const { name } = colony;
  const theme = useTheme();

  const Cell: React.FC<{
    children: ReactNode;
    colSpan?: number;
    textAlign?: string;
    showBoarder?: boolean;
    backgroundColor?: string;
  }> = ({
    children,
    colSpan,
    textAlign = "center",
    showBoarder = true,
    backgroundColor = "transparent",
  }) => {
    return (
      <Box
        component="td"
        sx={{
          width: "30px",
          textAlign,
          backgroundColor,
          border: showBoarder ? `1px solid ${theme.palette.divider}` : "none",
          // "& img": {
          //   width: "24px",
          //   height: "auto",
          // },
        }}
        colSpan={colSpan}
      >
        {children}
      </Box>
    );
  };

  const renderResourceImage = (resource: keyof ResourceAmountsType) => {
    return (
      <img
        src={resourceTypeToResourceImage(resource)}
        alt="Colony Bonus"
        style={{ width: "18px", padding: theme.spacing(0.1) }}
      />
    );
  };

  const renderColonyBonusRow = () => {
    const amount = Object.values(colony.colonyBonus)[0] ?? 0;
    const resourceType =
      (Object.keys(colony.colonyBonus)[0] as ResourceType) ?? 0;
    return (
      <Box component="tr">
        <Cell colSpan={2} showBoarder={false}>
          {Array.from({ length: amount }, () =>
            renderResourceImage(resourceType)
          )}
        </Cell>
        <Cell colSpan={4} showBoarder={false} textAlign={"left"}>
          Colony Bonus
        </Cell>
      </Box>
    );
  };

  const renderTradeIncomesRow = () => {
    const resourceType =
      (Object.keys(colony.tradeIncomes[0])[0] as ResourceType) ?? "";
    return (
      <Box component="tr">
        <Cell showBoarder={false}>x{renderResourceImage(resourceType)}</Cell>
        <Cell colSpan={3} showBoarder={false} textAlign={"left"}>
          Trade Bonus
        </Cell>
      </Box>
    );
  };

  const renderColonyTrack = () => {
    const placementBonusImages: ResourceType[] = colony.placementBonuses.map(
      (income) => (Object.keys(income)[0] as ResourceType) ?? ""
    );

    const values = colony.tradeIncomes.map(
      (tradeIncome) => Object.values(tradeIncome)[0] ?? ""
    );
    return (
      <>
        <Box component="tr">
          {colony.tradeIncomes.map((_, index) => (
            <Cell
              key={index}
              backgroundColor={
                colony.coloniesBuilt.length > index
                  ? colony.coloniesBuilt[index].color
                  : undefined
              }
            >
              {placementBonusImages[index]
                ? renderResourceImage(placementBonusImages[index])
                : null}
            </Cell>
          ))}
        </Box>
        <Box component="tr">
          {values.map((value, index) => (
            <Cell key={index} showBoarder={false}>
              {value}
            </Cell>
          ))}
        </Box>
        <Box component="tr">
          {colony.tradeIncomes.map((_, index) => (
            <Cell key={index} showBoarder={false}>
              {index === colony.markerLocation ? "▲" : null}
            </Cell>
          ))}
        </Box>
      </>
    );
  };

  return (
    <StyledCard>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              padding: theme.spacing(1),
            }}
          >
            {name}
          </Typography>
          <Box>{colony.currentTradeFleetPlayerId === -1 ? null : "🚀"}</Box>
        </Box>
        <Box>
          <Box component="table" sx={{ borderSpacing: "0", width: "100%" }}>
            <Box component="tbody">
              {renderColonyBonusRow()}
              {renderTradeIncomesRow()}
              {renderColonyTrack()}
            </Box>
          </Box>
        </Box>
      </CardContent>
    </StyledCard>
  );
};

Component.displayName = "ColonyTile";
