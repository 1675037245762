import React from "react";
import { Box } from "@mui/system";
import { CardType } from "api/game/GameType";
import * as GameCard from "components/pages/game/GameCard";

interface Props {
  cards: CardType[];
  selectedCards: CardType[];
  onToggleCard: (card: CardType) => void;
}

export const Component: React.FC<Props> = ({
  cards,
  selectedCards,
  onToggleCard,
}) => {
  const cardIndex = (card: CardType): number => {
    return selectedCards.map((c) => c.name).indexOf(card.name);
  };

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
      {cards.map((card, index) => (
        <Box
          key={index}
          sx={{
            padding: cardIndex(card) >= 0 ? "3px" : "4px",
            border: cardIndex(card) >= 0 ? "1px solid #0a9a00" : "none",
            backgroundColor: cardIndex(card) >= 0 ? "#fffd8e" : "transparent",
            boxSizing: "border-box",
            cursor: "pointer",
          }}
        >
          <GameCard.Component
            name={card.name}
            isCorporation={card.isCorporation}
            tooltip={card.tooltip}
            onClick={() => onToggleCard(card)}
          />
        </Box>
      ))}
    </Box>
  );
};

Component.displayName = "CardSelector";
