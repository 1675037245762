import React from "react";
import { Box, Tooltip } from "@mui/material";
import { TileType } from "api/game/GameType";
import { playerColorToBackgroundHex } from "utils/styling_helpers";
import { tileNameToImageName, STORAGE_BASE_URL } from "utils/constants";
import "components/pages/game/GameBoardTile.css";

export interface Props {
  tile: TileType;
  onClick: () => void; // Prop for handling tile clicks
  style: React.CSSProperties;
}

export const Component: React.FC<Props> = ({ tile, onClick, style }) => {
  const bonuses: string[] = [];
  Object.entries(tile.resources).forEach(([resource, count]) => {
    for (let i = 0; i < (count || 0); i++) {
      bonuses.push(resource);
    }
  });
  if (tile.cards && tile.cards > 0) {
    bonuses.push(...Array(tile.cards).fill("card"));
  }

  const tileTooltip =
    tile.type === "Empty"
      ? ""
      : `Tile: ${tile.plain_name}` +
        (tile.type === "Ocean"
          ? ""
          : `\n\nOwner: ${tile.owner?.name ?? "Neutral"}`);

  const hasOwner = (tile: TileType) => {
    return (
      (tile.owner && tile.name !== "LAND_CLAIM" && tile.owner.name) ||
      tile.type === "Ocean" ||
      tile.type === "Greenery" ||
      tile.type === "City"
    );
  };

  const isOcean = (tile: TileType) => {
    return tile.isOceanLocation || tile.type === "Ocean";
  };

  const isSpecial = (tile: TileType) => {
    return tile.type === "Special" && tile.name !== "LAND_CLAIM";
  };

  const isCity = (tile: TileType) => {
    return tile.type === "City" && tile.name !== "CAPITAL";
  };

  const isCapital = (tile: TileType) => {
    return tile.name === "CAPITAL";
  };

  const isGreenery = (tile: TileType) => {
    return tile.type === "Greenery";
  };

  return (
    <Tooltip title={tileTooltip} placement="top">
      <Box
        className="hexagon"
        onClick={onClick}
        sx={{
          ...style,
        }}
      >
        <Box
          className={`hexinner ${isOcean(tile) ? "ocean" : ""} ${
            hasOwner(tile) ? "has-owner" : "no-owner"
          } ${isSpecial(tile) ? "special" : ""} ${isCity(tile) ? "city" : ""} ${
            isCapital(tile) ? "capital" : ""
          } ${isGreenery(tile) ? "greenery" : ""}`}
        >
          <Box className="hexcontent">{tile.specialName}</Box>
          <Box className="placement_bonuses">
            {bonuses.map((bonus, index) => (
              <Box key={index} className={`bonus${index}`}>
                <img
                  src={`${STORAGE_BASE_URL}/icons/${bonus}_sm.jpg`}
                  width="12px"
                  alt={bonus}
                />
              </Box>
            ))}
            {tile.hasPlacementDecision &&
              tile.placementDecisionName === "SOUTH_POLE" && (
                <Box className="south-pole">
                  <img
                    src={`${STORAGE_BASE_URL}/icons/money_sm.jpg`}
                    width="20px"
                    alt="money-icon"
                    className="money-icon"
                  />
                  <img
                    src={`${STORAGE_BASE_URL}/icons/ocean.jpg`}
                    width="20px"
                    alt="ocean-icon"
                    className="ocean-icon"
                  />
                  <Box className="minus-money">-6</Box>
                </Box>
              )}
          </Box>
          {tile.type !== "Empty" && tile.name !== "LAND_CLAIM" && (
            <Box className="image-icon">
              <img
                src={`${STORAGE_BASE_URL}/icons/${
                  tileNameToImageName[tile.name]
                }.jpg`}
                width="20px"
                alt={tile.name}
              />
            </Box>
          )}
          {tile.owner && tile.owner.color && (
            <Box
              sx={{
                height: "20px",
                width: "20px",
                backgroundColor: playerColorToBackgroundHex(tile.owner.color),
              }}
            />
          )}
        </Box>
      </Box>
    </Tooltip>
  );
};

Component.displayName = "GameBoardTile";
