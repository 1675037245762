import React, { useContext } from "react";
import { Box, Card } from "@mui/material";
import { CardType } from "api/game/GameType";
import { useParams } from "react-router-dom";
import { GameContext } from "components/pages/game/GamePage";
import * as GameCard from "components/pages/game/GameCard";
import { executeGameAction } from "api/game/gameActions";
import { useSnackbar } from "notistack";

export interface Props {
  cards: CardType[];
}

export const Component: React.FC<Props> = ({ cards }) => {
  const { gameId } = useParams();
  const { setGame } = useContext(GameContext);
  const { enqueueSnackbar } = useSnackbar();

  const onClick = (cardName: string) => {
    if (gameId === undefined) {
      console.error("Game ID is undefined");
      return;
    }

    executeGameAction({
      gameId,
      ActionData: {
        card: cardName,
      },
      ActionId: "SELECT_CARD",
      DecisionId: "DISCARD_CARD",
      setGame,
      enqueueSnackbar,
    });
  };

  return (
    <Card
      sx={{
        borderRadius: 0,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h2>Choose Card to Discard</h2>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          padding: "10px",
          flexWrap: "wrap",
          width: "100%",
        }}
      >
        {cards.map((card) => (
          <Box
            sx={{
              padding: "10px",
            }}
          >
            <GameCard.Component
              key={card.name}
              name={card.name}
              isCorporation={card.isCorporation}
              tooltip={card.tooltip}
              onClick={() => onClick(card.name)}
            />
          </Box>
        ))}
      </Box>
    </Card>
  );
};

Component.displayName = "DiscardCard";
