import React, { useState } from "react";
import * as DecisionBaseModal from "components/pages/game/decision-modals/DecisionBaseModal";
import { CardType, TapOptionType } from "api/game/GameType";
import { Typography } from "@mui/material";
import * as GameCard from "components/pages/game/GameCard";
import * as TfmButton from "components/core/TfmButton";
import * as FundActionModal from "components/pages/game/decision-modals/FundActionModal";

export interface Props {
  card: CardType;
  isOpen: boolean;
  onClose: () => void;
  DecisionId?: string;
}

export const Component: React.FC<Props> = ({
  card,
  isOpen,
  onClose,
  DecisionId,
}) => {
  const [selectedTapOption, setSelectedTapOption] =
    useState<TapOptionType | null>(
      card.tapOptions.length === 1 ? card.tapOptions[0] : null
    );

  const renderContent = () => {
    return (
      <div>
        <Typography variant="body1">
          Confirm you want to tap <strong>{card.name}</strong>.
        </Typography>
      </div>
    );
  };

  const renderTapOptionButtons = () => {
    return (
      <div>
        <Typography variant="body1">
          Select an effect to apply when tapping the card.
        </Typography>
        {card.tapOptions.map((tapOption) => (
          <div key={tapOption.tapOption}>
            <TfmButton.Component
              onClick={() => setSelectedTapOption(tapOption)}
              text={tapOption.description}
              styles={{
                borderColor: "#007BFF",
                backgroundColor: "#007BFF",
                color: "#fff",
                ":hover": {
                  backgroundColor: "#0066CC",
                },
              }}
            />
          </div>
        ))}
      </div>
    );
  };

  const renderConfirmationModal = (option: TapOptionType) => {
    return (
      <FundActionModal.Component
        costsMoney={option.costsMoney ?? false}
        content={
          <div>
            {renderContent()}
            <div>
              <Typography variant="body1">
                Effect Selected: <strong>{option.description}</strong>
              </Typography>
            </div>
            <GameCard.Component name={card.name} tooltip={card.tooltip} />
          </div>
        }
        isOpen={isOpen}
        onClose={() => onClose()}
        card={card}
        ActionData={{
          tapOption: selectedTapOption?.tapOption,
        }}
        ActionId="TAP_CARD"
        DecisionId={DecisionId ?? "MAIN_ACTION"}
      />
    );
  };

  const renderTapOptionsModal = () => {
    return (
      <DecisionBaseModal.Component
        content={
          <div>
            {renderContent()}
            {renderTapOptionButtons()}
            <GameCard.Component name={card.name} tooltip={card.tooltip} />
          </div>
        }
        isOpen={isOpen}
        onClose={() => onClose()}
        ActionData={{
          card: card.name,
          tapOption: selectedTapOption?.tapOption,
        }}
        ActionId="TAP_CARD"
        DecisionId={DecisionId ?? "MAIN_ACTION"}
      />
    );
  };

  return selectedTapOption === null
    ? renderTapOptionsModal()
    : renderConfirmationModal(selectedTapOption);
};

Component.displayName = "TapCardModal";
