import React, { useState, useContext } from "react";
import { MilestoneOrAwardType } from "api/game/GameType";
import { Box, Button, Dialog, DialogTitle, DialogActions } from "@mui/material";
import { useParams } from "react-router-dom";
import { GameContext } from "components/pages/game/GamePage";
import { executeGameAction } from "api/game/gameActions";
import { useSnackbar } from "notistack";

export interface Props {
  milestoneOrAwards: MilestoneOrAwardType[];
  type: "milestone" | "award";
  isOpen: boolean;
  onClose: () => void;
}

export const Component: React.FC<Props> = ({
  milestoneOrAwards,
  type,
  isOpen,
  onClose,
}) => {
  const { gameId } = useParams();
  const { setGame } = useContext(GameContext);
  const { enqueueSnackbar } = useSnackbar();

  const onAction = ({
    name,
    type,
  }: {
    name: string;
    type: "milestone" | "award";
  }) => {
    if (gameId === undefined) {
      console.error("Game ID is undefined");
      return;
    }
    const ActionData =
      type === "milestone" ? { milestone: name } : { award: name };
    executeGameAction({
      gameId,
      ActionData,
      ActionId: type === "milestone" ? "CLAIM_MILESTONE" : "FUND_AWARD",
      DecisionId: "MAIN_ACTION",
      setGame,
      enqueueSnackbar,
    }).then(() => {
      onClose();
    });
  };

  const renderButton = (milestoneOrAward: MilestoneOrAwardType) => {
    return (
      <Button
        key={milestoneOrAward.name}
        variant="contained"
        onClick={() => onAction({ name: milestoneOrAward.name, type })}
        sx={{ m: 1, width: "250px" }}
      >
        {milestoneOrAward.plain_name}
      </Button>
    );
  };

  return (
    <Box>
      <Dialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby="confirm-decision-title"
      >
        <DialogTitle id="confirm-decision-title">Confirm Decision</DialogTitle>
        {type === "milestone" ? "Claim a Milestone" : "Claim an Award"}
        <DialogActions>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {milestoneOrAwards.map(renderButton)}
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

Component.displayName = "SelectMilestoneOrAwardModal";
