import React, { useState, useEffect, createContext, useRef } from "react";
import * as PlayerSummary from "components/pages/game/PlayerSummary";
import * as GameStatus from "components/pages/game/GameStatus";
import * as GameBoard from "components/pages/game/GameBoard";
import * as DraftCards from "components/pages/game/DraftCards";
import * as StartingHand from "components/pages/game/StartingHand";
import * as MainActions from "components/pages/game/MainActions";
import * as PlayerHand from "components/pages/game/PlayerHand";
import * as AllPlayerBoards from "components/pages/game/AllPlayerBoards";
import * as GameCard from "components/pages/game/GameCard";
import * as TradeWithColonyModal from "components/pages/game/decision-modals/TradeWithColonyModal";
import * as PlayerBoard from "components/pages/game/PlayerBoard";
import * as SolarActions from "components/pages/game/SolarActions";
import * as PurchaseCardDecision from "components/pages/game/decision-components/PurchaseCardDecision";
import * as ChooseAttackTarget from "components/pages/game/decision-components/ChooseAttackTarget";
import * as ChooseCardEffect from "components/pages/game/decision-components/ChooseCardEffect";
import * as TilePlacementDecision from "components/pages/game/decision-components/TilePlacementDecision";
import * as ChooseTargetCard from "components/pages/game/decision-components/ChooseTargetCard";
import * as PlaceTile from "components/pages/game/decision-components/PlaceTile";
import * as DiscardCard from "components/pages/game/decision-components/DiscardCard";
import * as TapAnotherCard from "components/pages/game/decision-components/TapAnotherCard";
import * as SellCards from "components/pages/game/decision-components/SellCards";
import * as FinishedGameSummary from "components/pages/game/FinishedGameSummary";
import * as ColonyTile from "components/pages/game/ColonyTile";
import { getGame } from "api/game/get-game";
import {
  isCurrentPlayersTurn,
  isSinglePlayerGame,
  isMultiplayerGame,
} from "utils/user_helpers";
import {
  GameType,
  ChooseEffectOptionType,
  ChooseCardDecisionType,
  ChooseAttackOptionType,
} from "api/game/GameType";
import { useParams, useLocation } from "react-router-dom";
import * as LoadingSpinner from "components/core/LoadingSpinner";
import * as CardHelpers from "utils/card_helpers";
import { Slide, SlideProps, Box } from "@mui/material";
import _ from "lodash";
import { useSnackbar, SnackbarKey } from "notistack";

export const GameContext = createContext<{
  game: GameType;
  setGame: (game: GameType) => void;
}>({
  game: {} as GameType,
  setGame: () => {},
});

export const Component: React.FC<{}> = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const lastSnackbarKeyRef = useRef<SnackbarKey | null>(null);
  const location = useLocation();
  const { gameId } = useParams();
  const [maybeGame, setMaybeGame] = useState<GameType | null>(null);
  const maybeGameRef = useRef<GameType | null>(null);
  const [openActionModalName, setOpenActionModalName] = useState<string>("");

  useEffect(() => {
    maybeGameRef.current = maybeGame;
    maybeRenderTurnBanner(maybeGame);
  }, [maybeGame]);

  // Remove the banner when the user navigates away
  useEffect(() => {
    return () => {
      if (lastSnackbarKeyRef.current !== null) {
        closeSnackbar(lastSnackbarKeyRef.current);
      }
    };
  }, [location.pathname, closeSnackbar]);

  const maybeRenderTurnBanner = (game: GameType | null) => {
    if (game === null) {
      if (lastSnackbarKeyRef.current !== null) {
        closeSnackbar(lastSnackbarKeyRef.current);
        lastSnackbarKeyRef.current = null;
      }
      return;
    }

    if (isSinglePlayerGame(game)) {
      return;
    }

    if (lastSnackbarKeyRef.current === null && isCurrentPlayersTurn(game)) {
      const key = enqueueSnackbar("It's your turn", {
        variant: "info",
        persist: true,
        style: {
          fontSize: "1.5em",
          textAlign: "center",
          height: "auto",
          padding: "16px",
        },
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        TransitionComponent: NoTransition,
      });

      lastSnackbarKeyRef.current = key;
    }

    if (lastSnackbarKeyRef.current !== null && !isCurrentPlayersTurn(game)) {
      closeSnackbar(lastSnackbarKeyRef.current);
      lastSnackbarKeyRef.current = null;
    }
  };

  const NoTransition = (props: SlideProps) => {
    return <Slide {...props} in={props.in} direction="down" />;
  };

  useEffect(() => {
    const fetchGameData = () => {
      if (gameId) {
        getGame(gameId).then((game) => {
          // Only update on change to prevent unnecessary re-renders
          if (!_.isEqual(game, maybeGameRef.current)) {
            setMaybeGame(game);
          }
        });
      }
    };

    // Initial call
    fetchGameData();

    // Set up polling
    const intervalId = setInterval(fetchGameData, 500);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, [gameId]);

  const renderPlayerCardsAndActions = (game: GameType) => {
    switch (game.phase) {
      case "INITIAL":
        return <StartingHand.Component />;
      case "CORE":
        return isCurrentPlayersTurn(game)
          ? renderCardsAndCoreActions(game)
          : renderOnlyCards(game);
      case "DRAFT":
        return renderDraftActions(game);
      case "SOLAR":
        return <SolarActions.Component />;
      case "FINAL_GREENERIES":
        return renderFinalGreeneries(game);
      case "FINAL":
        return renderFinalCardsAndBoard(game);
      default:
        return <div>{`Unimplemented game Phase: ${game.phase}`}</div>;
    }
  };

  const renderOnlyCards = (game: GameType) => {
    return (
      <>
        <PlayerHand.Component
          player={game.player}
          isClickable={game.decisionStack[0]?.UserId === game.player.UserId}
        />
        <PlayerBoard.Component
          cards={game.player.cardsOnBoard}
          canTap={isCurrentPlayersTurn(game)}
        />
      </>
    );
  };

  const renderCardsAndCoreActions = (game: GameType) => {
    return (
      <>
        {game.currentPlayerDecision !== "SELL" &&
        game.decisionStack[0]?.DecisionId !== "DISCARD_CARD" ? (
          <PlayerHand.Component
            player={game.player}
            isClickable={game.decisionStack[0]?.UserId === game.player.UserId}
          />
        ) : null}
        {maybeRenderDecision(game)}
        {game.currentPlayerDecision === "MAIN_ACTION" &&
        isCurrentPlayersTurn(game) ? (
          <MainActions.Component game={game} />
        ) : null}
        {game.currentPlayerDecision === "SELL" ? (
          <SellCards.Component cards={game.player.hand} />
        ) : null}
        <PlayerBoard.Component
          cards={game.player.cardsOnBoard}
          canTap={isCurrentPlayersTurn(game)}
        />
      </>
    );
  };

  const renderDraftActions = (game: GameType) => {
    return (
      <>
        <PlayerHand.Component player={game.player} isClickable={false} />
        <DraftCards.Component />
        <PlayerBoard.Component
          cards={game.player.cardsOnBoard}
          canTap={isCurrentPlayersTurn(game)}
        />
      </>
    );
  };

  const renderFinalGreeneries = (game: GameType) => {
    return (
      <>
        <PlayerHand.Component player={game.player} isClickable={false} />
        <PlaceTile.Component
          tileName={game.currentPlayerDecisionData?.tile ?? ""}
        />
        <PlayerBoard.Component
          cards={game.player.cardsOnBoard}
          canTap={false}
        />
      </>
    );
  };

  const renderFinalCardsAndBoard = (game: GameType) => {
    return (
      <>
        <PlayerHand.Component player={game.player} isClickable={false} />
        <PlayerBoard.Component
          cards={game.player.cardsOnBoard}
          canTap={isCurrentPlayersTurn(game)}
        />
      </>
    );
  };

  const renderFinalResult = (game: GameType) => {
    if (!game.finalScores) {
      return null;
    }

    return <FinishedGameSummary.Component finalScores={game.finalScores} />;
  };

  const maybeRenderDecision = (game: GameType) => {
    if (
      game.decisionStack.length === 0 ||
      game.currentPlayerDecisionData === undefined
    ) {
      return null;
    }

    const decisionId = game.decisionStack[0]?.DecisionId;
    const decisionData = game.currentPlayerDecisionData;

    if (decisionId === "CHOOSE_CARD_EFFECT") {
      return (
        <ChooseCardEffect.Component
          cardName={decisionData.card}
          options={decisionData.options as ChooseEffectOptionType[]}
          description={decisionData.description}
          isCardOptions={decisionData.isCardOptions ?? false}
        />
      );
    }
    if (decisionId === "TARGET_CARD") {
      return (
        <ChooseTargetCard.Component
          options={decisionData.options as ChooseCardDecisionType[]}
          canChooseNone={decisionData.canChooseNone}
        />
      );
    }
    if (decisionId === "DISCARD_CARD") {
      return <DiscardCard.Component cards={game.player.hand} />;
    }
    if (decisionId === "PLACE_TILE") {
      return (
        <PlaceTile.Component
          tileName={game.currentPlayerDecisionData?.tile ?? ""}
        />
      );
    }
    if (decisionId === "CHOOSE_ATTACK_TARGET") {
      return (
        <ChooseAttackTarget.Component
          options={decisionData.options as ChooseAttackOptionType[]}
        />
      );
    }
    if (decisionId === "PURCHASE_CARD_DECISION") {
      return (
        <PurchaseCardDecision.Component
          cardName={decisionData.available?.name ?? ""}
          tooltip={decisionData.available?.tooltip ?? ""}
        />
      );
    }
    if (decisionId === "TILE_PLACEMENT_DECISION") {
      return (
        <TilePlacementDecision.Component
          options={decisionData.options as ChooseEffectOptionType[]}
        />
      );
    }
    if (decisionId === "TRADE_COLONY") {
      return (
        <TradeWithColonyModal.Component
          isOpen={openActionModalName === "TRADE_COLONY"}
          onClose={() => setOpenActionModalName("")}
        />
      );
    }

    if (decisionId === "VIRON_TAP") {
      return (
        <TapAnotherCard.Component
          cards={game.player.cardsOnBoard
            .filter((card) => card.hasBeenTapped)
            .filter((card) => card.name !== "Viron")}
        />
      );
    }

    return (
      <Box sx={{ color: "white" }}>
        Unimplemented decisionId {game.decisionStack[0]?.DecisionId}
      </Box>
    );
  };

  const renderGame = (game: GameType) => {
    return (
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Box sx={{ width: "650px" }}>
            <GameStatus.Component
              oceans={9 - game.oceansRemaining}
              oxygen={game.oxygenSteps}
              temperature={game.temperatureSteps}
              venus={game.venusSteps}
              generation={game.generation}
              milestones={game.milestones}
              awards={game.awards}
              isSinglePlayer={isSinglePlayerGame(game)}
            />

            <GameBoard.Component game={game} />
            {game.colonyTiles.map((colonyTile) => (
              <Box
                sx={{ display: "flex", flexDirection: "row", width: "300px" }}
              >
                <ColonyTile.Component colony={colonyTile} />
              </Box>
            ))}
          </Box>
          <Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <PlayerSummary.Component
                game={game}
                name={game.player.name}
                isCurrentTurn={
                  game.decisionStack[0]?.UserId === game.player.UserId
                }
                hasPassedForGen={game.player.passedThisGeneration}
                resources={game.player.resources}
                production={game.player.production}
                events={
                  game.player.cardsOnBoard.filter(CardHelpers.isEventCard)
                    .length
                }
                terraformRating={game.player.terraformRating}
                turnOrder={game.player.orderThisGen}
                playerColor={game.player.color}
                numberOfCardsInHand={game.player.hand.length}
                cardPoints={game.player.cardPoints}
                totalTradeFleets={game.player.totalTradeFleets}
                userId={game.player.UserId}
              />
            </Box>
            {renderPlayerCardsAndActions(game)}
          </Box>
        </Box>
        {game.phase === "FINAL" ? renderFinalResult(game) : null}
        {isMultiplayerGame(game) ? (
          <Box>
            <AllPlayerBoards.Component />
          </Box>
        ) : null}
      </Box>
    );
  };

  if (maybeGame === null) {
    return <LoadingSpinner.Component />;
  }

  return (
    <div>
      <GameContext.Provider value={{ game: maybeGame, setGame: setMaybeGame }}>
        {renderGame(maybeGame)}
      </GameContext.Provider>
    </div>
  );
};

Component.displayName = "GamePage";
