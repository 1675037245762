import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Maps } from "utils/constants";
import {
  getGameLobby,
  GameLobbyType,
  PlayerType,
} from "api/game-lobby/get-game-lobby";
import { joinGameLobby } from "api/game-lobby/join-lobby";
import { leaveGameLobby } from "api/game-lobby/leave-lobby";
import { updateGameLobby } from "api/game-lobby/update-lobby";
import { startGame } from "api/game/start-game";
import { useParams } from "react-router-dom";

import {
  CircularProgress,
  Button,
  Checkbox,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Card,
  CardContent,
  Typography,
  Box,
} from "@mui/material";
import { CurrentUserContext } from "../../../App";

export const Component: React.FC<{}> = () => {
  const { currentUser } = useContext(CurrentUserContext);
  const navigate = useNavigate();
  const [maybeLobbyInfo, setMaybeLobbyInfo] = useState<GameLobbyType | null>(
    null
  );
  const { lobbyId } = useParams();

  useEffect(() => {
    if (lobbyId) {
      getGameLobby(lobbyId).then(setMaybeLobbyInfo);
    }
  }, [lobbyId]);

  const renderCurrentPlayersList = (players: PlayerType[]) => {
    return (
      <div>
        {players.map((player) => (
          <Card key={player._id} sx={{ marginBottom: 2 }}>
            <CardContent>
              <Typography variant="h6" component="div">
                {player.name}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </div>
    );
  };

  const renderJoinOrLeaveLobbyButton = (lobbyInfo: GameLobbyType) => {
    const isInLobby = lobbyInfo.players.some(
      (player) => player.UserId === currentUser?._id
    );
    return isInLobby
      ? renderLeaveLobbyButton(lobbyInfo._id)
      : renderJoinLobbyButton(lobbyInfo._id);
  };

  const renderJoinLobbyButton = (lobbyId: string) => {
    return (
      <Button
        onClick={() =>
          joinGameLobby(lobbyId).then((maybeLobby) => {
            setMaybeLobbyInfo(maybeLobby);
          })
        }
        color="primary"
        variant="contained"
      >
        Join Lobby
      </Button>
    );
  };

  const renderLeaveLobbyButton = (lobbyId: string) => {
    return (
      <Button
        onClick={() =>
          leaveGameLobby(lobbyId).then((maybeLobby) => {
            setMaybeLobbyInfo(maybeLobby);
          })
        }
        color="primary"
        variant="contained"
      >
        Leave Lobby
      </Button>
    );
  };

  const renderMapSelection = (lobbyInfo: GameLobbyType) => {
    return (
      <Card sx={{ margin: 2, maxWidth: 345 }}>
        <CardContent>
          <FormControl component="fieldset">
            <FormLabel component="legend" sx={{ color: "text.secondary" }}>
              Map
            </FormLabel>
            <RadioGroup
              name="map-selector"
              value={lobbyInfo.mapName}
              onChange={(event) => {
                const value = event.target.value;
                if (!lobbyId) {
                  console.error("No lobbyId");
                  return;
                }
                updateGameLobby(lobbyId, { mapName: value }).then(() => {
                  getGameLobby(lobbyId).then(setMaybeLobbyInfo);
                });
              }}
            >
              {Object.values(Maps).map((option) => (
                <FormControlLabel
                  key={option}
                  value={option}
                  control={<Radio />}
                  label={option}
                  disabled={lobbyInfo.creator.UserId !== currentUser?._id}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </CardContent>
      </Card>
    );
  };

  const renderGameOptions = (lobbyInfo: GameLobbyType) => {
    return (
      <Card sx={{ margin: 2, maxWidth: 345 }}>
        <CardContent>
          <FormControl
            component="fieldset"
            sx={{
              border: "1px solid #ddd",
              borderRadius: "4px",
              padding: 2,
              margin: 1,
            }}
          >
            <FormLabel component="legend" sx={{ color: "text.secondary" }}>
              Options
            </FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={lobbyInfo.venusActive}
                    onChange={(_, checked) => {
                      updateGameLobby(lobbyInfo._id, {
                        venusActive: checked,
                      }).then(setMaybeLobbyInfo);
                    }}
                  />
                }
                label="Venus Active"
                disabled={lobbyInfo.creator.UserId !== currentUser?._id}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={lobbyInfo.solarActive}
                    onChange={(_, checked) => {
                      updateGameLobby(lobbyInfo._id, {
                        solarActive: checked,
                      }).then(setMaybeLobbyInfo);
                    }}
                  />
                }
                label="Solar Active"
                disabled={lobbyInfo.creator.UserId !== currentUser?._id}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={lobbyInfo.coloniesActive}
                    onChange={(_, checked) => {
                      updateGameLobby(lobbyInfo._id, {
                        coloniesActive: checked,
                      }).then(setMaybeLobbyInfo);
                    }}
                  />
                }
                label="Colonies Active (In progress)"
                disabled={lobbyInfo.creator.UserId !== currentUser?._id}
              />{" "}
            </FormGroup>
          </FormControl>
        </CardContent>
      </Card>
    );
  };

  const renderStartGameButton = (lobby: GameLobbyType) => {
    return (
      <Button
        disabled={lobby.creator.UserId !== currentUser?._id}
        onClick={() =>
          startGame(lobby._id).then((maybeGameId: string | null) => {
            if (maybeGameId !== null) {
              navigate(`/game/${maybeGameId}`);
            } else {
              // TODO - show user an error
            }
          })
        }
        color="primary"
        variant="contained"
      >
        Start Game
      </Button>
    );
  };

  const renderLobby = (lobbyInfo: GameLobbyType) => {
    return (
      <Box sx={{ width: "100%", maxWidth: 500, mx: "auto" }}>
        <Typography
          variant="h4"
          gutterBottom
          color="textPrimary"
          style={{ color: "#fff" }}
        >
          {lobbyInfo.name}
        </Typography>
        <Typography
          variant="h6"
          gutterBottom
          color="textPrimary"
          style={{ color: "#fff" }}
        >
          Current Players
        </Typography>
        {renderCurrentPlayersList(lobbyInfo.players)}
        {renderJoinOrLeaveLobbyButton(lobbyInfo)}
        <Typography
          variant="h4"
          gutterBottom
          color="textPrimary"
          style={{ color: "#fff" }}
        >
          Game Information
        </Typography>
        <Typography
          variant="h6"
          gutterBottom
          color="textPrimary"
          style={{ color: "#fff" }}
        >
          Creator: {lobbyInfo.creator.name}
        </Typography>
        {renderMapSelection(lobbyInfo)}
        {renderGameOptions(lobbyInfo)}
        {renderStartGameButton(lobbyInfo)}
      </Box>
    );
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography
        variant="h5"
        gutterBottom
        color="textPrimary"
        style={{ color: "#fff" }}
      >
        Lobby Page
      </Typography>
      {maybeLobbyInfo === null ? (
        <CircularProgress />
      ) : (
        renderLobby(maybeLobbyInfo)
      )}
    </Box>
  );
};

Component.displayName = "LobbyPage";
