import React, { useState, useContext, useEffect } from "react";
import * as PlayerSummary from "components/pages/game/PlayerSummary";
import { GeneralPlayerType } from "api/game/GameType";
import { GameContext } from "components/pages/game/GamePage";
import * as CardHelpers from "utils/card_helpers";
import { Box, Typography } from "@mui/material";
import * as PlayerBoard from "components/pages/game/PlayerBoard";
import { isSpecificPlayersTurn } from "utils/user_helpers";
import _ from "lodash";
import { useNavigate, useLocation } from "react-router-dom";

interface Props {}

export const Component: React.FC<Props> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const { game } = useContext(GameContext);

  const [selectedPlayerId, setSelectedPlayerId] = useState<string | null>(null);

  useEffect(() => {
    const playerId = queryParams.get("selectedPlayerId");
    setSelectedPlayerId(playerId);
  }, [location]);

  const updateQueryParams = (selectedPlayerId: string) => {
    queryParams.set("selectedPlayerId", selectedPlayerId);
    navigate(`${location.pathname}?${queryParams.toString()}`, {
      replace: true,
    });
  };

  const renderPlayerSummary = ({
    player,
    isSelected,
  }: {
    player: GeneralPlayerType;
    isSelected: boolean;
  }) => {
    return (
      <Box
        sx={{
          flexGrow: 1,
          display: "block",
          textAlign: "center",
        }}
        key={player.UserId}
      >
        <Box
          sx={{
            display: "block",
            padding: isSelected ? "7px" : "8px",
            border: "1px solid #0a9a00",
            backgroundColor: isSelected ? "#fffd8e" : "transparent",
            boxSizing: "border-box",
            cursor: "pointer",
            textAlign: "center",
            maxWidth: "350px",
          }}
          onClick={() => {
            updateQueryParams(player.UserId);
          }}
        >
          <PlayerSummary.Component
            game={game}
            name={player.name}
            isCurrentTurn={isSpecificPlayersTurn({
              game,
              userId: player.UserId,
            })}
            hasPassedForGen={player.passedThisGeneration}
            resources={player.resources}
            production={player.production}
            events={player.cardsOnBoard.filter(CardHelpers.isEventCard).length}
            terraformRating={player.terraformRating}
            turnOrder={player.orderThisGen}
            playerColor={player.color}
            numberOfCardsInHand={player.numCardsInHand}
            cardPoints={player.cardPoints}
            totalTradeFleets={player.totalTradeFleets}
            userId={player.UserId}
          />
        </Box>
      </Box>
    );
  };

  const maybeRenderSelectedPlayerBoard = () => {
    const selectedPlayer = game.allPlayers.find(
      (player) => player.UserId === selectedPlayerId
    );

    if (!selectedPlayer) {
      return null;
    }

    return (
      <PlayerBoard.Component
        cards={selectedPlayer.cardsOnBoard}
        canTap={false}
      />
    );
  };

  return (
    <Box>
      <Box sx={{ backgroundColor: "#999999", textAlign: "center" }}>
        <Typography variant="h5" sx={{ mt: 4 }}>
          View Player Boards
        </Typography>
      </Box>
      <Box
        sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
      >
        {game.allPlayers.map((player) =>
          renderPlayerSummary({
            player,
            isSelected: player.UserId === selectedPlayerId,
          })
        )}
      </Box>
      <Box>{maybeRenderSelectedPlayerBoard()}</Box>
    </Box>
  );
};

Component.displayName = "AllPlayerBoards";
