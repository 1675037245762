import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { forgotPassword } from "api/user/forgot-password";
import { useSnackbar } from "notistack";

export interface Props {
  onComplete: () => void;
}

export const Component: React.FC<Props> = ({ onComplete }) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [email, setEmail] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onEmailInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const updatedEmail = event.currentTarget.value;
    setEmail(updatedEmail);
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    forgotPassword({ email, enqueueSnackbar })
      .then(() => {
        onComplete();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Card
      className="ForgotPasswordForm"
      sx={{ maxWidth: 345, m: "auto", mt: 5 }}
    >
      <CardContent>
        <Typography variant="h5" component="h2" gutterBottom>
          Reset Password
        </Typography>
        <form onSubmit={onSubmit}>
          <TextField
            label="Email"
            variant="outlined"
            type="text"
            value={email}
            onChange={(e) => onEmailInputChange(e)}
            fullWidth
            margin="normal"
          />
          {isLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Button type="submit" variant="contained" sx={{ mt: 2 }} fullWidth>
              Send Password Reset
            </Button>
          )}
        </form>
      </CardContent>
    </Card>
  );
};

Component.displayName = "ForgotPasswordForm";
